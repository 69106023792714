.mingl-blue100 {
  color: #E6F9FF; }

.bg-mingl-blue100 {
  background-color: #E6F9FF; }

.mingl-blue200 {
  color: #B8E8F9; }

.bg-mingl-blue200 {
  background-color: #B8E8F9; }

.mingl-blue300 {
  color: #89C5E1; }

.bg-mingl-blue300 {
  background-color: #89C5E1; }

.mingl-blue400 {
  color: #4099BF; }

.bg-mingl-blue400 {
  background-color: #4099BF; }

.mingl-blue500 {
  color: #3683B0; }

.bg-mingl-blue500 {
  background-color: #3683B0; }

.mingl-blue600 {
  color: #1E5776; }

.bg-mingl-blue600 {
  background-color: #1E5776; }

.mingl-blue700 {
  color: #20485C; }

.bg-mingl-blue700 {
  background-color: #20485C; }

.mingl-blue800 {
  color: #00384D; }

.bg-mingl-blue800 {
  background-color: #00384D; }

.mingl-blue900 {
  color: #002531; }

.bg-mingl-blue900 {
  background-color: #002531; }

.mingl-red100 {
  color: #FFF1F1; }

.bg-mingl-red100 {
  background-color: #FFF1F1; }

.mingl-red200 {
  color: #FECBCB; }

.bg-mingl-red200 {
  background-color: #FECBCB; }

.mingl-red300 {
  color: #F48889; }

.bg-mingl-red300 {
  background-color: #F48889; }

.mingl-red400 {
  color: #F16667; }

.bg-mingl-red400 {
  background-color: #F16667; }

.mingl-red500 {
  color: #CC3D3C; }

.bg-mingl-red500 {
  background-color: #CC3D3C; }

.mingl-red600 {
  color: #AF2928; }

.bg-mingl-red600 {
  background-color: #AF2928; }

.mingl-red700 {
  color: #8D1111; }

.bg-mingl-red700 {
  background-color: #8D1111; }

.mingl-red800 {
  color: #6F0606; }

.bg-mingl-red800 {
  background-color: #6F0606; }

.mingl-red900 {
  color: #520100; }

.bg-mingl-red900 {
  background-color: #520100; }

.mingl-blue {
  color: #00384d; }

.bg-mingl-blue {
  background-color: #00384d; }

.mingl-light-blue {
  color: #5a768f; }

.bg-mingl-light-blue {
  background-color: #5a768f; }

.mingl-green {
  color: #00b7ae; }

.bg-mingl-green {
  background-color: #00b7ae; }

.mingl-green100 {
  color: #E1FFF5; }

.bg-mingl-green100 {
  background-color: #E1FFF5; }

.mingl-green200 {
  color: #BAFDE6; }

.bg-mingl-green200 {
  background-color: #BAFDE6; }

.mingl-green300 {
  color: #8BE9CE; }

.bg-mingl-green300 {
  background-color: #8BE9CE; }

.mingl-green400 {
  color: #63D4BE; }

.bg-mingl-green400 {
  background-color: #63D4BE; }

.mingl-green500 {
  color: #44C3B6; }

.bg-mingl-green500 {
  background-color: #44C3B6; }

.mingl-green600 {
  color: #29A8A0; }

.bg-mingl-green600 {
  background-color: #29A8A0; }

.mingl-green700 {
  color: #19807E; }

.bg-mingl-green700 {
  background-color: #19807E; }

.mingl-green800 {
  color: #125959; }

.bg-mingl-green800 {
  background-color: #125959; }

.mingl-green900 {
  color: #084245; }

.bg-mingl-green900 {
  background-color: #084245; }

.button-green {
  color: #2ecc71; }

.bg-button-green {
  background-color: #2ecc71; }

.mingl-light-green {
  color: #00d8cd; }

.bg-mingl-light-green {
  background-color: #00d8cd; }

.mingl-bright-green {
  color: #539e54; }

.bg-mingl-bright-green {
  background-color: #539e54; }

.mingl-red {
  color: #cc3d3c; }

.bg-mingl-red {
  background-color: #cc3d3c; }

.mingl-light-red {
  color: #f16667; }

.bg-mingl-light-red {
  background-color: #f16667; }

.mingl-yellow {
  color: #ffd450; }

.bg-mingl-yellow {
  background-color: #ffd450; }

.mingl-light-yellow {
  color: #fce497; }

.bg-mingl-light-yellow {
  background-color: #fce497; }

.mingl-black {
  color: #1d1d1d; }

.mingl-light-black {
  color: #333333; }

.bg-mingl-light-black {
  background-color: #333333; }

.mingl-gray50 {
  color: #F2F6F8; }

.mingl-gray100 {
  color: #E5ECF1; }

.mingl-gray200 {
  color: #E4EAEE; }

.mingl-gray300 {
  color: #D9E0E3; }

.mingl-gray400 {
  color: #A6B6BE; }

.mingl-gray500 {
  color: #BAC4C9; }

.mingl-gray600 {
  color: #6E767A; }

.mingl-gray700 {
  color: #4C585E; }

.mingl-gray800 {
  color: #303A3F; }

.mingl-gray900 {
  color: #1A2124; }

.mingl-gray {
  color: #717171; }

.mingl-light-gray {
  color: #eee; }

.mingl-mid-gray {
  color: #555555; }

.bg-mingl-light-gray {
  background-color: #eee; }

.bg-mingl-mid-gray {
  background-color: #555555; }

.bg-mingl-gray {
  background-color: #717171; }

.mingl-placeholder-light {
  color: #BBBBBB; }

.bg-mingl-steel-blue {
  background-color: #5080B3; }

.bg-mingl-baby-blue {
  background-color: #DBF2F8; }

.mingl-baby-blue {
  color: #DBF2F8; }

.mingl-select-blue {
  color: #38A1F2; }

.bg-mingl-select-blue {
  background-color: #38A1F2; }

.animate-search-enter {
  display: block;
  opacity: 0.3;
  flex: 0;
  transition: flex 225ms ease-out, opacity 225ms ease-out; }
  .animate-search-enter.animate-search-enter-active {
    opacity: 1;
    flex: 1 1 auto; }

.animate-search-enter-done {
  display: block; }

.animate-search-exit {
  display: block;
  opacity: 1;
  transition: flex 225ms ease-in, opacity 225ms ease-in; }
  .animate-search-exit.animate-search-exit-active {
    opacity: 0;
    flex: 0; }

.animate-search-exit-done {
  display: none; }

.mingl-input {
  border: none;
  box-shadow: none;
  color: #EBEEF2;
  font-weight: 300; }
  .mingl-input:focus {
    color: #000;
    background-color: #fff; }

.mingl-home-search {
  margin: 30px 0; }
  .mingl-home-search .mingl-search-loader {
    background-color: white;
    color: #333;
    margin-left: 0px;
    margin-right: 0px;
    text-align: center; }

.custom-popup-wrapper {
  top: 100%;
  max-height: 380px;
  overflow-x: hidden;
  overflow-y: scroll;
  z-index: 1000;
  display: none;
  background-color: #f9f9f9; }

@media screen and (min-width: 45em) {
  .db-mid-m-l {
    display: block; } }

.mingl-dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  background-clip: padding-box; }
