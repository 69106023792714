.loader:before,
.loader:after,
.loader {
  border-radius: 50%;
  width: 1.0em;
  height: 1.0em;
  animation-fill-mode: both;
  animation: loaderAnimation 1.8s infinite ease-in-out; }

.loader {
  font-size: inherit;
  margin: -1.5em auto 1em auto;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  animation-delay: -0.16s; }

.loader:before {
  left: -1.5em;
  animation-delay: -0.32s; }

.loader:after {
  left: 1.5em; }

.loader:before,
.loader:after {
  content: '';
  position: absolute;
  top: 0; }

@keyframes loaderAnimation {
  0%,
  80%,
  100% {
    box-shadow: 0 1em 0 -0.5em; }
  40% {
    box-shadow: 0 1em 0 0; } }

.card-content {
  padding-top: 2rem;
  padding-right: 1rem;
  padding-bottom: 3rem;
  padding-left: 1rem; }

.modal-shadow {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5); }

.modal-background {
  z-index: 1000;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  text-align: center;
  transition: background-color .20s linear;
  background-color: rgba(0, 0, 0, 0); }
  .modal-background.fade-in {
    background-color: rgba(0, 0, 0, 0.6); }
  .modal-background.fade-out {
    background-color: rgba(0, 0, 0, 0.6); }

.card-modal {
  outline: 0;
  position: relative;
  pointer-events: none; }
  .card-modal.appear {
    opacity: 0.01;
    transform: scale(0.9) translate3d(0, 50%, 0);
    transition-duration: 225ms;
    transition-timing-function: ease-out;
    transition-property: opacity, transform; }
    .card-modal.appear.appear-enter {
      opacity: 1;
      transform: scale(1) translate3d(0, 0%, 0); }
    .card-modal.appear.appear-exit {
      opacity: 0;
      transition-duration: 195ms;
      transition-timing-function: ease-in;
      transform: scale(0.9) translate3d(0, 50%, 0); }

.modal-content-container {
  pointer-events: auto;
  display: inline-block; }

.animate-reverse .card-internal-enter {
  z-index: 150;
  opacity: 0.01;
  transform: translateX(-120%); }

.animate-reverse .card-internal-enter.card-internal-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: transform 225ms ease-out, opacity 225ms ease-out; }

.animate-reverse .card-internal-exit {
  z-index: 100;
  opacity: 1;
  transform: translateX(0); }

.animate-reverse .card-internal-exit.card-internal-exit-active {
  top: 0;
  opacity: 0.01;
  position: absolute;
  transform: translateX(100%);
  transition: transform 195ms ease-in, opacity 150ms linear; }

.animate-forward .card-internal-enter {
  z-index: 150;
  opacity: 0.01;
  transform: translateX(120%); }

.animate-forward .card-internal-enter.card-internal-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: transform 225ms ease-out, opacity 225ms ease-out; }

.animate-forward .card-internal-exit {
  z-index: 100;
  opacity: 1;
  transform: translateX(0); }

.animate-forward .card-internal-exit.card-internal-exit-active {
  top: 0;
  opacity: 0.01;
  position: absolute;
  transform: translateX(-100%);
  transition: transform 195ms ease-in, opacity 150ms linear; }
