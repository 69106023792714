.loader:before,
.loader:after,
.loader {
  border-radius: 50%;
  width: 1.0em;
  height: 1.0em;
  animation-fill-mode: both;
  animation: loaderAnimation 1.8s infinite ease-in-out; }

.loader {
  font-size: inherit;
  margin: -1.5em auto 1em auto;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  animation-delay: -0.16s; }

.loader:before {
  left: -1.5em;
  animation-delay: -0.32s; }

.loader:after {
  left: 1.5em; }

.loader:before,
.loader:after {
  content: '';
  position: absolute;
  top: 0; }

@keyframes loaderAnimation {
  0%,
  80%,
  100% {
    box-shadow: 0 1em 0 -0.5em; }
  40% {
    box-shadow: 0 1em 0 0; } }

.selector-background {
  z-index: 1000;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  text-align: center;
  transition: background-color .20s linear;
  background-color: rgba(0, 0, 0, 0);
  display: flex;
  align-items: center;
  justify-content: center; }
  .selector-background.fade-in {
    background-color: rgba(0, 0, 0, 0.6); }
  .selector-background.fade-out {
    background-color: rgba(0, 0, 0, 0.6); }

.selector-modal {
  outline: 0;
  min-width: 320px; }
  .selector-modal.appear {
    opacity: 0.01;
    transform: scale(0.9) translate3d(0, 50%, 0);
    transition-duration: 225ms;
    transition-timing-function: ease-out;
    transition-property: opacity, transform; }
    .selector-modal.appear.appear-enter {
      opacity: 1;
      transform: scale(1) translate3d(0, 0%, 0); }
    .selector-modal.appear.appear-exit {
      opacity: 0;
      transition-duration: 195ms;
      transition-timing-function: ease-in;
      transform: scale(0.9) translate3d(0, 50%, 0); }
